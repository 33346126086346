import React from 'react';
import './Footer.css';

function footer(){
    return (
    <section className="footer">
        <div className="container">
            <div className="text-center">
                <h6>Question? Contact Me</h6>
                <a href="mailto: whtp1490@gmail.com"><i className="bi bi-google fs-2 footericon"></i></a>&nbsp;&nbsp;
                <a href="https://github.com/welly091/"><i className="bi bi-github fs-2 footericon"></i></a>&nbsp;&nbsp;
                <a href="https://www.linkedin.com/in/welly-yu-wei-hsieh-7b11a0b4/"><i className="bi bi-linkedin fs-2 footericon"></i></a>&nbsp;&nbsp;
                <a href="https://twitter.com/GeekyWelly"><i className="bi bi-twitter fs-2 footericon"></i></a>
                <p className="fs-7">© 2022 Powered by GeekyWelly.com</p>
            </div>
        </div>
    </section>
    )
}
export default footer;