import './App.css';
import { lazy } from 'react';
import {Routes, Route} from 'react-router-dom';
import MyNav from './components/Navbar/Navbar.jsx';
import Footer from './components/Footer/Footer.jsx';

const Home = lazy(() =>import('./components/Home/Home.jsx'))
const VocabGame = lazy(() => import('./components/VocabGame/VocabGame.jsx'))

export default function App() {
  return (
    <div>
      <MyNav/>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        {/*<Route path="/" element={<Projects/>}></Route>
         <Route path="/blog" element={<Blog/>}></Route>
        <Route path='/blog/:id' element={<Post/>}></Route> */}
        <Route path="/vocabgame" element={<VocabGame/>}></Route>
      </Routes>
      <Footer/>
    </div>
  );
}