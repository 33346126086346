import React from 'react'
import './Navbar.css'
import { Navbar, Container, Nav } from 'react-bootstrap'
import {Link} from 'react-scroll';

 export default function MyNav(){  
    return (
        <Navbar className='navbar' expand='lg'>
            <Container className='col-md'>
                    <Nav.Link className="navbarkids" href="/" eventKey="link-1">Home</Nav.Link>
                    {
                        window.location.pathname === "/" 
                        ? <Link className="navbarkids" to="project" smooth={true}>Projects</Link>
                        : <Nav.Link className="navbarkids" href="/" eventKey="link-1">Projects</Nav.Link>
                    }
                    
                    <Nav.Link className="navbarkids" href="/vocabgame" >Vocab</Nav.Link>
            </Container>
        </Navbar>
    )
}
